import React from "react";
import Header from "../components/Header";
import "../Styles/mobileapp.css";
import mobileservice from "../data/mobileservice.json";
import Footer from "../components/Footer";
import Slider from "react-slick";

function Webdevelop() {
  var slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <section className="S-section-1">
        <div className="home-container">
          <div className="row">
            <div
              data-aos="fade-right"
              data-aos-duration="700"
              className="col col-sm-6 col-md-6 col-lg-6 col-xl-6 "
            >
              <div className="S-section1-content">
                <h2>WEB DEVELOPMENT COMPANY</h2>
                <p>
                  Our team of seasoned professionals is committed to delivering
                  customised solutions tailored to your unique needs. We pride
                  ourselves on our ability to provide exceptional service and
                  support, ensuring that you receive the highest level of
                  satisfaction with every project we undertake.
                </p>
                <button>Talk to our expert</button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              className=" col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section1-img">
                <img
                  src={require("../assets/serivce/web.png")}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-2">
        <div className="service-container">
          <div className="row">
            <div
              data-aos="fade-right"
              className=" col-sm-12 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="S-section2-content">
                <h4>OUR SERVICES</h4>
                <h2>Our web Development Services</h2>
                <p>
                  At RUTILANT, We Specialize In Delivering Custom Web
                  Development Solutions That Perfectly Align With Your Business
                  Objectives. Our Experienced Team Of Developers Collaborates
                  Closely With Clients To Understand Their Unique Requirements,
                  Ensuring That Each Website We Create Is Not Just Visually
                  Stunning But Also Functionally Robust. From Responsive Design
                  To Seamless User Experiences, We Pride Ourselves On Delivering
                  Tailored Solutions That Make A Lasting Impact In The Digital
                  Landscape.
                </p>
                <p>
                  Stay Ahead In The Digital Realm With Our Web Development
                  Services That Harness The Power Of Cutting-Edge Technologies.
                  We Are Committed To Staying At The Forefront Of Industry
                  Trends, Incorporating The Latest Tools And Frameworks To Build
                  Websites That Are Not Only Modern But Also Scalable And
                  Future-Proof. Whether It's A Sleek Corporate Site, An Engaging
                  E-Commerce Platform, Or A Dynamic Web Application, Our
                  Expertise Ensures That Your Online Presence Reflects The
                  Innovation Synonymous With Your Brand.
                </p>
                <p>
                  We Believe In A Results-Driven Approach To Web Development.
                  Our Focus Goes Beyond Just Creating Visually Appealing
                  Websites; We Aim To Deliver Tangible Results For Your
                  Business. From Optimizing Site Performance For Better User
                  Engagement To Implementing Robust SEO Strategies, We Ensure
                  That Your Website Becomes A Powerful Tool In Achieving Your
                  Digital Marketing Goals. Trust Us To Transform Your Online
                  Presence Into A Dynamic And Effective Asset For Your Business
                  Success.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className=" col-sm-12 col-md-6 col-lg-6 col-xl-6"
            >
              {mobileservice.map((item, index) => (
                <div className="service-box" key={index}>
                  <div className="d-flex">
                    <img
                      src={`${window.location.origin}/assets/service/${item.image}`}
                      alt={item.image}
                    />
                    <h6>{item.title}</h6>
                  </div>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-3">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section3-title">
              <h4>Technology Stack</h4>
              <h2>Technologies & Platforms We Work With</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm-6 col-md-10 col-lg-6 col-xl-10 m-auto">
              <Slider {...slider}>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/fluterlogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>Flutter</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/nativelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>React Native</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/vuelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>vue js</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/htmllogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>html</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/csslogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>css</h6>
                </div>
                <div className="launguage-logo">
                  <img
                    src={require("../assets/serivce/nativelogo.png")}
                    alt=""
                    srcset=""
                  />
                  <h6>React Native</h6>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="S-section-4">
        <div className="service-container">
          <div className="row">
            <div data-aos="fade-down" className="S-section4-title">
              <h4>OUR WORK</h4>
              <h2>web DEVELOPMENT PROJECT</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-1.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-2.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-3.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="portfolio-card"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <img
                  src={require("../assets/portfolio/portfolio-work-4.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="portfolio-card-content">
                  <a
                    href="#"
                    className="link-icon"
                    data-aos="link-icon-animation"
                  >
                    <i className="fa-solid fa-link"></i>
                  </a>
                  <h6 data-aos="h6-animation">ENTERTAINMENT</h6>
                  <h1 data-aos="h1-animation">Happy Snappy Lite</h1>
                </div>
                <span className="border-animation-1"></span>
                <span className="border-animation-2"></span>
                <span className="border-animation-3"></span>
                <span className="border-animation-4"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Webdevelop;
