import React, { useEffect } from "react";
import Header from "../components/Header";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import Typewriter from 'typewriter-effect';

import "../Styles/home.css";
import "aos/dist/aos.css";
import ourserviceData from "../data/ourservice.json";
import develomentProcess from "../data/developmentprocess.json";
import 'animate.css';

import introduce from "../data/introduce.json";
import { useState } from "react";

// React Slick Carousel Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Home() {
  const [software, SetSoftware] = useState(1);
  const [ourserviceType, setOurServiceType] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const changeourServiceType = (type) => {
    setOurServiceType(type);
    if (type == "software") {
      SetSoftware(1);
    } else {
      SetSoftware(0);
    }
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", reveal);
  }, [])

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <i
        className="fa-solid fa-angle-right icon-right"
        style={{ ...style }}
        onClick={onClick}
      ></i>
    );
  }

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <i
        className="fa-solid fa-angle-left icon-left"
        style={{ ...style }}
        onClick={onClick}
      ></i>
    );
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 361,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false
        },

      }
    ]
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 432,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  return (
    <>
      <Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      <section className="section-1">
        <div className="home-container">
          <div className="row">
            <div data-aos="fade-right" data-aos-duration="700" className=" col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <div className="section1-content">
                <h2>Experience Unmatched Efficiency with Our </h2>
                <Typewriter
                  onInit={(animation) => {
                    for (let index = 0; index < 100; index++) {
                      animation.typeString("WEB DEVELOPMENT")
                        .pauseFor(2000)
                        .deleteAll()
                      animation.typeString("MOBILE APP DEVELOPMENT")
                        .pauseFor(2000)
                        .deleteAll()
                      animation.typeString("SOFTWARE DEVELOPMENT")
                        .pauseFor(2000)
                        .deleteAll()
                      animation.typeString("NFT DEVELOPMENT")
                        .pauseFor(2000)
                        .deleteAll()
                      animation.typeString("DIGITAL MARKETING")
                        .pauseFor(2000)
                        .deleteAll()
                      animation.typeString("UI/UX DESINGING")
                        .pauseFor(2000)
                        .deleteAll()
                        .start()
                    }
                  }} />
                <p>
                  Our team of seasoned professionals is committed to delivering
                  customised solutions tailored to your unique needs. We pride
                  ourselves on our ability to provide exceptional service and
                  support, ensuring that you receive the highest level of
                  satisfaction with every project we undertake.
                </p>
                <button>Talk to our expert</button>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="700"
              className=" col-sm-12 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="section1-img">
                <img src={require("../assets/home/section1img.png")} alt="section1img.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-2">
        <div className="home-container">
          <div className="row">
            <div data-aos="fade-right" data-aos-duration="700" className="col  col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <div className="section2-img">
                <img src={require("../assets/home/section2img2.png")} alt="section2img2.png" />
              </div>
              <div data-aos="fade-up" data-aos-delay="50" data-aos-duration="12000" className="years">
                <h4>08+</h4>
                <h6>Years of Experience</h6>
              </div>
              <div data-aos="fade-up" data-aos-duration="10000" data-aos-delay="2500" className="client">
                <h4>100+</h4>
                <h6>Pleased Client</h6>
              </div>
              <div data-aos="fade-up" data-aos-duration="5000" data-aos-delay="1800" className="projects">
                <h4>50+</h4>
                <h6>Projects Delivered</h6>
              </div>
              <div data-aos="fade-up" data-aos-duration="7000" data-aos-delay="1000" className="projects2">
                <h4>50+</h4>
                <h6>Projects Delivered</h6>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="700" className="col col-sm-12 col-md-6 col-lg-6 col-xl-6  ">
              <div className="section2-content">
                <h6>about</h6>
                <h2>Bringing New IT Business Solutions And Ideas</h2>
                <p>
                  Welcome To Rutilant Technology! We Are A Team Of Passionate And Experienced Professionals Who Are Dedicated To Providing Innovative And Reliable Technology Solutions To Our Clients. Our Company Was Founded With The Vision Of Leveraging Cutting-Edge Technology To Solve Complex Business Problems And Empower Organisations To Achieve Their Goals. We Specialise In A Wide Range Of IT Services, Including Software Development, Web Development, Mobile App Development, Cloud Computing, Data Analytics, Cybersecurity, And IT Consulting.
                </p>
                <button>Know More</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-3">
        <div className="home-container">
          <div className="row">
            <div className="section3-content-title">
              <h4>OUR SERVICES</h4>
              <h2>Build your success business with us</h2>
            </div>
          </div>
          <div className="row categery">
            <ul className="service-menu">
              <li className={`${software == 1 ? "active-li" : ""}`}>
                <button onClick={() => changeourServiceType("software")}>
                  Software Development
                </button>
              </li>
              <li className={`${ourserviceType.includes("web") ? "active-li" : ""}`}>
                <button onClick={() => changeourServiceType("web")}>
                  Web development
                </button>
              </li>
              <li className={`${ourserviceType.includes("ntf") ? "active-li" : ""}`}>
                <button onClick={() => changeourServiceType("ntf")}>
                  Nft Development
                </button>
              </li>
              <li className={`${ourserviceType.includes("app") ? "active-li" : ""}`}>
                <button onClick={() => changeourServiceType("app")}>
                  app Development
                </button>
              </li>
              <li className={`${ourserviceType.includes("digital") ? "active-li" : ""}`}>
                <button onClick={() => changeourServiceType("digital")}>
                  Digital Marketing
                </button>
              </li>
              <li className={`${ourserviceType.includes("ui") ? "active-li" : ""}`}>
                <button onClick={() => changeourServiceType("ui")}>
                  UI/UX designing
                </button>
              </li>
            </ul>
            <hr />
          </div>
          <div className="row d-md-none">
            <ul className="service-menu">
              <Slider {...settings}>
                <li className={`${software == 1 ? "active-li" : ""}`}>
                  <button onClick={() => changeourServiceType("software")}>
                    Software Development
                  </button>
                </li>
                <li className={`${ourserviceType.includes("web") ? "active-li" : ""}`}>
                  <button onClick={() => changeourServiceType("web")}>
                    Web development
                  </button>
                </li>
                <li className={`${ourserviceType.includes("ntf") ? "active-li" : ""}`}>
                  <button onClick={() => changeourServiceType("ntf")}>
                    Nft Development
                  </button>
                </li>
                <li className={`${ourserviceType.includes("app") ? "active-li" : ""}`}>
                  <button onClick={() => changeourServiceType("app")}>
                    app Development
                  </button>
                </li>
                <li className={`${ourserviceType.includes("digital") ? "active-li" : ""}`}>
                  <button onClick={() => changeourServiceType("digital")}>
                    Digital Marketing
                  </button>
                </li>
                <li className={`${ourserviceType.includes("ui") ? "active-li" : ""}`}>
                  <button onClick={() => changeourServiceType("ui")}>
                    UI/UX designing
                  </button>
                </li>
              </Slider>
            </ul>
            <hr />
          </div>
          {
            software == 1 ? (
              ourserviceData
                .map((item, index) => {
                  if (item.type === "software") {
                    return (
                      <div className="row second-row" key={index}>
                        <div
                          data-aos="fade-right"
                          data-aos-duration="500"
                          className="col col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                        >
                          <div className="section3-content">
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                          </div>
                          <div className="language-icon">
                            {item.languageicon.map((language, index) => (
                              <div key={index}>
                                <img
                                  src={`${window.location.origin}/assets/home/${language.iconimg}`}
                                  alt={language.iconimg}
                                />
                                <p>{language.icontitle}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          data-aos="fade-left"
                          data-aos-duration="700"
                          className="col col-sm-12 col-md-6 col-lg-6 col-xl-6"
                        >
                          <div className="section3-img">
                            <img
                              src={`${window.location.origin}/assets/home/${item.image}`}
                              alt={item.image}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
            ) : (
              ourserviceData
                .map((item, index) => {
                  if (item.type === ourserviceType) {
                    return (
                      <div className="row second-row" key={index}>
                        <div
                          data-aos="fade-right"
                          data-aos-duration="500"
                          className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="section3-content">
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                          </div>
                          <div className="language-icon">
                            {item.languageicon.map((language, index) => (
                              <div key={index}>
                                <img
                                  src={`${window.location.origin}/assets/home/${language.iconimg}`}
                                  alt={language.iconimg}
                                />
                                <p>{language.icontitle}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          data-aos="fade-left"
                          data-aos-duration="700"
                          className="col col-sm-12 col-md-6 col-lg-6 col-xl-6"
                        >
                          <div className="section3-img">
                            <img
                              src={`${window.location.origin}/assets/home/${item.image}`}
                              alt={item.image}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
            )
          }
        </div>
      </section>
      <section className="section-4">
        <div className="home-container">
          <div className="row">
            <div data-aos="fade-down" data-aos-delay="400" data-aos-duration="500" className="section4-content-title">
              <h4>How we work</h4>
              <h2>DEVELOPMENT PROCESS</h2>
            </div>
          </div>
          <div className="row margin-top">
            {develomentProcess.map((item, index) => (
              <div className="col col-sm-6 col-md-3  col-lg-3 col-xl-3 vv" key={index}>
                <div className="developbox reveal zoom-animation">
                  <div className="d-flex">
                    <img
                      src={`${window.location.origin}/assets/home/${item.processicon}`}
                      alt={item.image}
                    />
                  </div>
                  <div className="line"></div>
                  <h6>{item.title}</h6>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>

        </div>
      </section>
      <section className="section-5">
        <div className="home-container">
          <div className="row">
            <div data-aos="fade-down" data-aos-delay="400" data-aos-duration="500" className="section-5-content-title">
              <h4>OUR PORTFOLIO</h4>
              <h2>Introduce Our Projects</h2>
            </div>
          </div>
          <div className="row slider">
            <div className="col col-sm-12 col-md-12 col-lg-12  col-xl-12">
              <Slider {...settings2}>
                {introduce.map((item, index) => (
                  <div className="d-flex rev" key={index}>
                    <div data-aos="fade-right"
                      data-aos-duration="1000" className="slider-content">
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                      <button>Know More</button>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="3000" className="slider-img">
                      <img
                        src={`${window.location.origin}/assets/home/${item.images}`}
                        alt={item.image} className="img-fluid"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-6">
        <BlogCommon />
      </section> */}
      <section className="section-7">
        <Testimonials />
      </section>
      <Footer />
    </>
  );
}

export default Home;
